import React from "react";
import "./../DashboardStyles.scss";
import { ReactComponent as ArrowUp } from "../../../../Assets/common/images/arrowUp.svg";
import { ReactComponent as ArrowDown } from "../../../../Assets/common/images/arrowDown.svg";
import { ReactComponent as ArrowDownGreen } from "../../../../Assets/common/images/arrowDownGreen.svg";
import { ReactComponent as ArrowUpRed } from "../../../../Assets/common/images/arrowUpRed.svg";

interface StatisticsProps {
  score?: number;
  difference?: number;
  title: string;
  time?: number;
}
export const Statistics: React.FC<StatisticsProps> = (props) => {
  const { score, difference, title } = props;

  if (title == "Tiempo promedio" && difference) {
    return (
      <>
        <div className="registrations-statistics">
          <div className="registrations-statistics-title text-bold primary-color">
            {title}
          </div>
          {score !== undefined && (
            <div className="registrations-statistics-number">{score} </div>
          )}
          <div
            style={{
              color:
                difference < 0
                  ? "chartreuse"
                  : difference > 0
                  ? "#ee6666"
                  : "inherit",
              fontSize: "15px",
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            {difference > 0 ? (
              <>
                <ArrowUpRed
                  style={{ height: "12px", width: "20px", color: "red" }}
                />
                {difference}
              </>
            ) : difference < 0 ? (
              <>
                <ArrowDownGreen style={{ height: "12px", width: "20px" }} />
                {difference}
              </>
            ) : (
              0
            )}
          </div>
        </div>
      </>
    );
  }
  return (
    <div className="registrations-statistics">
      <div className="registrations-statistics-title text-bold primary-color">
        {title}
      </div>

      {score !== undefined && (
        <div className="registrations-statistics-number">{score} </div>
      )}

      {difference !== undefined && (
        <div
          style={{
            color:
              difference < 0
                ? "#ee6666"
                : difference > 0
                ? "chartreuse"
                : "inherit",
            fontSize: "15px",
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          {difference > 0 ? (
            <>
              <ArrowUp style={{ height: "12px", width: "20px" }} />
              {difference}
            </>
          ) : difference < 0 ? (
            <>
              <ArrowDown style={{ height: "12px", width: "20px" }} />
              {difference}
            </>
          ) : (
            0
          )}
        </div>
      )}
    </div>
  );
};
