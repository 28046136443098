import { useEffect } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { formatDateStandar } from "../../../../helpers/utils";
import {
  selectLoginStatusReportsComponent,
  getFilteredReport,
  selectActiveDashboardComponent,
  selectCurrentFilteredDashboard,
  selectFilteredReport,
  selectCurrentPageReports,
  selectNumPagesReports,
  selectColumnsReport,
  changePageState,
} from "../DashboardSlice";
import { changeCurrentProduct } from "../../../common/commonSlice";
import "./../DashboardStyles.scss";

export const Reports = () => {
  let loading = useAppSelector(selectLoginStatusReportsComponent);
  let dispatch = useAppDispatch();
  let activeComponent = useAppSelector(selectActiveDashboardComponent);
  let currentFilteredRequest = useAppSelector(selectCurrentFilteredDashboard);
  let dataReports = useAppSelector(selectFilteredReport);
  let columnsReport = useAppSelector(selectColumnsReport);
  let currentPage = useAppSelector(selectCurrentPageReports);
  let numPages = useAppSelector(selectNumPagesReports);

  let changePage = (type: "before" | "after") => {
    if (type === "after" && currentPage < numPages - 1) {
      dispatch(changePageState(currentPage + 1));
    } else if (type === "before" && currentPage > 0) {
      dispatch(changePageState(currentPage - 1));
    }
  };

  useEffect(() => {
    dispatch(changeCurrentProduct("Dashboard"));

    dispatch(
      getFilteredReport({
        filter: currentFilteredRequest,
      })
    );
  }, [activeComponent, currentFilteredRequest]);

  return (
    <LoadingOverlay
      active={loading === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      {dataReports && dataReports.reportData.length > 0 ? (
        <div className="flex-col" style={{ width: "100%", height: "95%" }}>
          <div
            className="flex-row"
            style={{
              height: "20%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="text-xx-large text-bold primary-color"
              style={{ marginRight: "2%" }}
            >
              Registros realizados
            </div>
          </div>
          {dataReports.reportData ? (
            <div className="flex-col" style={{ height: "65%" }}>
              <div style={{ overflowX: "auto" }}>
                <table
                  className="table-report"
                  style={{
                    width: "max-content",
                    borderCollapse: "collapse",
                  }}
                >
                  <thead
                    className="text-normal text-medium primary-color-35"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      height: "30px",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      {columnsReport?.columns.map(
                        (column) =>
                          column.active && (
                            <th style={{ padding: "5px" }} key={column.field}>
                              {column.name}
                            </th>
                          )
                      )}
                    </tr>
                  </thead>
                  <tbody
                    className="text-normal text-x-small primary-color"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "center",
                    }}
                  >
                    {dataReports.reportData.map((reg) => {
                      return (
                        <tr
                          key={reg._id}
                          className="list-hover"
                          style={{
                            height: "30px",
                            cursor: "pointer",
                          }}
                        >
                          {columnsReport?.columns.map(
                            (column) =>
                              column.active && (
                                <td key={column.field}>
                                  {column.field === "startDatetime"
                                    ? formatDateStandar(reg.startDatetime)
                                    : column.field === "currentAssignees"
                                    ? Array.isArray(reg.currentAssignees) &&
                                      reg.currentAssignees.length > 0
                                      ? reg.currentAssignees.map(
                                          (item, index) => (
                                            <span key={index}>{item}</span>
                                          )
                                        )
                                      : ""
                                    : reg[column.field as keyof typeof reg]}
                                </td>
                              )
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="flex-col" style={{ height: "60%" }}>
              <h1 className="text-huge text-bold primary-color">
                No hay registros en esta página
              </h1>
            </div>
          )}
          <div
            className="flex-row"
            style={{
              justifyContent: "end",
              alignItems: "center",
              height: "5%",
            }}
          >
            <div
              className="flex-row text-bold primary-color"
              style={{
                marginRight: "100px",
                fontSize: "13px",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  changePage("before");
                }}
                style={{
                  width: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  fontSize: "15px",
                  height: "30px",
                  textAlign: "center",
                  border: "none",
                  background: "rgba(0,0,0,0.0)",
                  color: "white",
                  boxSizing: "border-box",
                }}
              >
                {"<"}
              </button>
              Página {currentPage + 1} de {numPages}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  changePage("after");
                }}
                style={{
                  width: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  fontSize: "15px",
                  height: "30px",
                  textAlign: "center",
                  border: "none",
                  background: "rgba(0,0,0,0.0)",
                  color: "white",
                  boxSizing: "border-box",
                }}
              >
                {">"}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex-row text-bold primary-color"
          style={{
            width: "100%",
            height: "100%",
            fontSize: "13px",
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loading === "pending" || loading === "idle"
              ? ""
              : <p>No hay más registros en esta página</p>}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
