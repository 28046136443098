import {
  getFunnelChartCoreID,
  getFunnelChartHyperFlow,
} from "../utils/EchartsUtils";
import ReactECharts from "echarts-for-react";
import "./../DashboardStyles.scss";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  getFunnelData,
  selectLoadingDataFunnel,
  selectDataFunnel,
  selectCurrentFilteredDashboard,
  selectDataFlowIdHF,
  selectDataFunnelHF,
  getConvertibilityData,
  getFlowDataHF,
  getFunnelHyperFlowById,
  clearFunnelHyperFlowData,
  clearFlowsHyperFlowData,
} from "../DashboardSlice";
import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { ConvertibilityRate } from "./ConvertibilityRate";

export const ConvertibilityFunnel = () => {
  let dispatch = useAppDispatch();
  let funnel = useAppSelector(selectDataFunnel);
  let dataFlowHF = useAppSelector(selectDataFlowIdHF);
  let dataFunnelHyperFlow = useAppSelector(selectDataFunnelHF);
  let currentFilteredRequest = useAppSelector(selectCurrentFilteredDashboard);
  let loadingDataFunnel = useAppSelector(selectLoadingDataFunnel);
  const [selectorFunnel, setSelectorFunnel] = useState<
    "Funnel CoreID" | "Funnel HyperFlow"
  >("Funnel CoreID");
  const [selectedFlowId, setSelectedFlowId] = useState<string | null>(null);
  const { funnelData } = funnel || {};

  useEffect(() => {
    clearFlowsHyperFlowData();

    dispatch(
      getFunnelData({
        filter: currentFilteredRequest,
      })
    );

    dispatch(
      getFlowDataHF({
        filter: currentFilteredRequest,
      })
    );

    dispatch(
      getConvertibilityData({
        filter: currentFilteredRequest,
      })
    );

    return () => {
      dispatch(clearFlowsHyperFlowData());
    };
  }, [currentFilteredRequest]);

  useEffect(() => {
    clearFunnelHyperFlowData();
    if (dataFlowHF?.data && dataFunnelHyperFlow.length === 0) {
      dataFlowHF.data.map((item) => {
        dispatch(
          getFunnelHyperFlowById({
            id: item._id,
            filter: currentFilteredRequest,
          })
        );
      });
    }

    if (dataFlowHF && dataFlowHF.data && dataFlowHF.data.length > 0) {
      setSelectedFlowId(dataFlowHF.data[0]._id);
    }

    return () => {
      dispatch(clearFunnelHyperFlowData());
    };
  }, [dataFlowHF]);

  const handleSelectChange = (event: any) => {
    setSelectedFlowId(event.target.value);
  };

  const selectedFlowData = selectedFlowId
    ? dataFunnelHyperFlow.find((flow) => flow.idFlow === selectedFlowId)
    : null;

  const funnelOptions = getFunnelChartHyperFlow(
    (selectedFlowData?.data?.currentStepStats["1"] ?? 0),
    (selectedFlowData?.data?.currentStepStats["FORMULARIO"] ?? 0),
    (selectedFlowData?.data?.currentStepStats["END"] ?? 0)
  );

  const funnelCoreIDOptions = funnelData
    ? getFunnelChartCoreID(
        funnelData.documentFrontCapture,
        funnelData.documentBackCapture,
        funnelData.documentFilters,
        funnelData.documentValidity,
        funnelData.liveness,
        funnelData.facematch,
        funnelData.otp,
        funnelData.email,
        funnelData.govEntity,
        funnelData.documentVeracity,
        funnelData.initialDataMatch,
        funnelData.lists,
        funnelData.percentDocumentBackCapture,
        funnelData.percentDocumentFilters,
        funnelData.percentDocumentValidity,
        funnelData.percentLiveness,
        funnelData.percentFacematch,
        funnelData.percentOtp,
        funnelData.percentEmail,
        funnelData.percentGovEntity,
        funnelData.percentDocumentVeracity,
        funnelData.percentInitialDataMatch,
        funnelData.percentLists
      )
    : "";

  return (
    <div className="container-funnel">
      <LoadingOverlay
        active={loadingDataFunnel === "pending"}
        spinner={<ClipLoader color="white" size="60px" />}
      >
        <div className="funnel-header">
          <div className="funnel-btns">
            <button
              className="btn-funnel custom-select"
              onClick={() => {
                setSelectorFunnel("Funnel CoreID");
              }}
            >
              <span className="btn-text">
                Embudo de convertibilidad Core ID
              </span>
            </button>
            <button
              className="btn-funnel custom-select"
              onClick={() => {
                setSelectorFunnel("Funnel HyperFlow");
              }}
            >
              <span className="btn-text">
                Embudo de convertibilidad HyperFlow
              </span>
            </button>
          </div>
        </div>
        <div className="funnel-graph">
          <div className="titleFunnel">
            <span className="titleFunnel-text">
              {selectorFunnel === "Funnel CoreID"
                ? "Embudo de convertibilidad CoreID"
                : "Embudo de convertibilidad HyperFlow"}
            </span>
          </div>
          {selectorFunnel === "Funnel CoreID" && funnel ? (
            <div
            className="flex-col"
              style={{
                alignItems: "center",
              }}
            >
              <ConvertibilityRate />
              <ReactECharts
                option={funnelCoreIDOptions ? funnelCoreIDOptions : ""}
                style={{ height: "490px", width: "100%" }}
              />
            </div>
          ) : (
            <>
              {selectorFunnel === "Funnel HyperFlow" && dataFlowHF?.data ? (
                <>
                  {dataFlowHF && dataFlowHF.data.length > 1 ? (
                    <p className=" text-medium  text-bold primary-color-50" style={{margin:"0", textAlign:"center", width:"227px"}}>
                      Seleccione un flujo
                    </p>
                  ) : (
                    ""
                  )}
                  <select
                    onChange={handleSelectChange}
                    style={{
                      backgroundColor: "rgb(0, 0, 0, 0.3)",
                      border: "none",
                      borderRadius: "10px",
                      padding: "10px",
                      cursor: "pointer",
                      textAlign:"center"
                    }}
                    className="text-large custom-select primaryTextColor text-normal "
                  >
                    {dataFlowHF &&
                      dataFlowHF.data.map((flow) => (
                        <option key={flow._id} value={flow._id}>
                          {flow.name}
                        </option>
                      ))}
                  </select>
                </>
              ): ""}

              {selectedFlowData && (
                <ReactECharts
                  option={funnelOptions}
                  style={{ height: "490px", width: "100%" }}
                />
              )}
            </>
          )}
        </div>
      </LoadingOverlay>
    </div>
  );
};
