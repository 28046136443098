export const getGaugeOption = (
  title: string,
  scoreIndicator: number,
  deferenceIndicator: number,
  scoreUmbral: number,
  deferenceUmbral: number
) => {
  const gaugeData = [
    {
      value: scoreIndicator,
      name: "score",
      title: {
        offsetCenter: ["0%", "50%"],
        color: "white",
        fontSize: 15,
      },
      detail: {
        valueAnimation: false,
        offsetCenter: ["0%", "75%"],
        fontSize: 20,
        color: "white",
        formatter: "{value}%",
      },
    },
  ];

  let colorConfig = [
    [0.7, "#ee6666"],
    [0.85, "#fac858"],
    [1, "#3ba272"],
  ];

  if (title === "Users confirm data") {
    colorConfig = [
      [0.15, "#3ba272"],
      [0.25, "#fac858"],
      [1, "#ee6666"],
    ];
  }

  return {
    series: [
      {
        type: "gauge",
        radius: "72%",
        center: ["50%", "28%"],
        axisLine: {
          lineStyle: {
            width: 11,
            color: colorConfig,
          },
        },
        axisTick: {
          distance: -21,
          length: 11,
          lineStyle: {
            color: "#fff",
            width: 0.3,
          },
        },
        splitLine: {
          distance: -22,
          length: 10,
          lineStyle: {
            color: "#fff",
            width: 1,
          },
        },
        axisLabel: {
          color: "white",
          distance: -18,
          fontSize: 15,
        },
        pointer: {
          itemStyle: {
            color: "auto",
          },
        },
        detail: {
          valueAnimation: true,
          formatter: "{value}",
          color: "inherit",
        },
        data: gaugeData,
      },
    ],
    graphic: [
      {
        type: "group",
        left: "center",
        bottom: 130,
        textAlign: "center",
        children: [
          {
            type: "text",

            style: {
              text: `${
                deferenceIndicator > 0 ? "↑" : deferenceIndicator < 0 ? "↓" : ""
              } ${deferenceIndicator}%`,
              fill:
                deferenceIndicator > 0
                  ? "#3ba272"
                  : deferenceIndicator < 0
                  ? "#ee6666"
                  : "white",
              fontSize: 15,
              textAlign: "center",
            },
            top: 2,
          },
          {
            type: "text",
            style: {
              text: "% Success Users",
              fontSize: 14,
              fill: "white",
              textAlign: "center",
            },
            top: 24,
          },
          {
            type: "text",
            style: {
              text: `${scoreUmbral}%`,
              fontWeight: 550,
              fontSize: 19,
              fill: "white",
              textAlign: "center",
            },
            top: 40,
          },
          {
            type: "text",
            style: {
              text: `${
                deferenceUmbral > 0 ? "↑" : deferenceUmbral < 0 ? "↓" : ""
              } ${deferenceUmbral}%`,
              fill:
                deferenceUmbral > 0
                  ? "#3ba272"
                  : deferenceUmbral < 0
                  ? "#ee6666"
                  : "inherit",
              textAlign: "center",
              fontSize: 15,
            },
            top: 60,
          },
        ],
      },
    ],
  };
};

export const getPieOption = (
  porcentage: boolean,
  review: number,
  approved: number,
  reject: number,
  inprogress: number,
  initialnodata: number,
  notopencamara: number,
  datanotfound: number
) => {
  let pieConfig = [
    { value: approved, name: "APROBADO", itemStyle: { color: "#3ba272" } }, // Verde
    { value: review, name: "POR REVISAR", itemStyle: { color: "#885255" } }, // Naranja
    { value: inprogress, name: "EN PROGRESO", itemStyle: { color: "#fac858" } }, // Amarillo
    { value: reject, name: "RECHAZADO", itemStyle: { color: "#ee6666" } }, // Rojo
    {
      value: notopencamara,
      name: "NO ABRE CAMARA",
      itemStyle: { color: "#800080" },
    }, // Púrpura
    { value: initialnodata, name: "SIN DATOS INICIALES" }, // Gris
    { value: datanotfound, name: "SIN DATOS" }, // Negro
  ];

  if (datanotfound == 0) {
    pieConfig = [
      {
        value: datanotfound,
        name: "SIN DATOS",
        itemStyle: { color: "#000000" },
      },
    ];
  }

  return {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} | ({d}%)",
    },
    legend: {
      orient: "vertical",
      bottom: 70,
      left: "right",
      data: [
        "APROBADO",
        "EN PROGRESO",
        "RECHAZADO",
        "POR REVISAR",
        "NO ABRE CAMARA",
        "SIN DATOS INICIALES",
      ],
      textStyle: {
        color: "white",
        fontSize: 10,
      },
    },
    series: [
      {
        name: "Estados de los registros",
        type: "pie",
        radius: "70%",
        center: ["50%", "39%"],
        selectedMode: "single",
        data: pieConfig,
        emphasis: {
          itemStyle: {
            shadowBlur: 15,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.6)",
            shadowOpacity: 1,
          },
        },
        label: {
          position: "inside",
          color: "white",
          fontWeight: 400,
          formatter: porcentage ? "{d}%" : "{c}",
          fontSize: 13,
        },
      },
    ],
  };
};
interface FunnelChartData {
  value: number;
  name: string;
  extra: number;
}

const formattedData = (numberFormatted: number | undefined) =>
  typeof numberFormatted === "number"
    ? parseFloat(numberFormatted.toPrecision(2))
    : undefined;

export const getFunnelChartCoreID = (
  documentFrontCapture: number,
  documentBackCapture: number,
  documentFilters: number,
  documentValidity: number,
  liveness: number,
  facematch: number,
  otp: number,
  email: number,
  govEntity: number,
  lists: number,
  documentVeracity: number,
  initialDataMatch: number,
  percentDocumentBackCapture: number,
  percentDocumentFilters: number,
  percentDocumentValidity: number,
  percentLiveness: number,
  percentFacematch: number,
  percentOtp: number,
  percentEmail: number,
  percentGovEntity: number,
  percentDocumentVeracity: number,
  percentInitialDataMatch: number,
  percentLists: number
) => {
  const metrics = [
    "Captura de documento frontal",
    "Captura de documento posterior",
    "Filtros de documento",
    "Validez del documento",
    "Liveness",
    "Facematch",
    "OTP",
    "Correo electrónico",
    "Entidad gubernamental",
    "Veracidad del documento",
    "Coincidencia de datos iniciales",
    "Listas",
  ];

  const percentMetrics = [
    documentFrontCapture? 100.0:0, // DocumentFrontCaptura
    percentDocumentBackCapture,
    percentDocumentFilters,
    percentDocumentValidity,
    percentLiveness,
    percentFacematch,
    percentOtp,
    percentEmail,
    percentGovEntity,
    percentDocumentVeracity,
    percentInitialDataMatch,
    percentLists,
  ];

  const data = metrics.map((metrics, index) => {
    const value = formattedData(percentMetrics[index]);
    return {
      value: value == undefined ? 0 : value,
      name: metrics,
    };
  });

  return {
    title: {
      top: 50,
      left: 55,
    },
    tooltip: {
      trigger: "item",
      formatter: (params: {
        seriesName: string;
        name: string;
        value: number;
        data: FunnelChartData;
      }) => {
        return `${params.seriesName} <br/>${params.name} ${params.value}`;
      },
    },
    toolbox: {
      feature: {
        dataView: { readOnly: true },
        restore: {},
        saveAsImage: {},
      },
    },
    legend: {
      data: metrics,
      textStyle: {
        color: "#color",
      },
    },

    series: [
      {
        name: "Convertibilidad coreID",
        type: "funnel",
        left: "25%",
        top: 80,
        bottom: 50,
        width: "55%",
        sort: "descending",
        gap: 5,
        label: {
          show: true,
          position: "inside",
          formatter: (params: {
            name: string;
            value: number;
            data: FunnelChartData;
          }) => {
            return `${params.value} %`;
          },
          fontSize: 15,
          color: "white",
        },
        itemStyle: {
          margin: 10,
          borderColor: "black",
          borderWidth: 1,
        },

        emphasis: {
          label: {
            fontSize: 20,
          },
        },
        data,
      },
      {
        name: "Convertibilidad coreID",
        type: "funnel",
        left: "30%",
        top: 90,
        bottom: 50,
        width: "55%",
        sort: "descending",
        gap: 3,
        show: false,
        label: {
          show: true,
          position: "leftTop",
          formatter: (params: {
            name: string;
            value: number;
            data: FunnelChartData;
          }) => {
            return `${params.name} ${params.value}`;
          },
          fontSize: 13,
          color: "white",
          padding: 100,
        },
        itemStyle: {
          borderColor: "rgba(0, 0, 0, 0.0)", // Semitransparente para las fronteras
          borderWidth: 1,
          color: "rgba(255, 255, 255, 0.0)", // Transparente para el área del embudo
        },

        emphasis: {
          label: {
            fontSize: 15,
          },
        },
        data: [
          { value: documentFrontCapture?documentFrontCapture:0, name: "Captura de documento frontal :" },
          { value: documentBackCapture ?documentBackCapture:0, name: "Captura de documento posterior :" },
          { value: documentFilters?documentFilters:0, name: "Filtros de documentos :" },
          { value: documentValidity?documentValidity:0, name: "Validez del documento :" },
          { value: liveness?liveness:0, name: "Liveness :" },
          { value: facematch?facematch:0, name: "Facematch :" },
          { value: otp?otp:0, name: "OTP :" },
          { value: email?email:0, name: "Correo Eletronico :" },
          { value: govEntity?govEntity:0, name: "Entidad gubernamental :" },
          { value: documentVeracity?documentVeracity:0, name: "Veracidad del documento :" },
          { value: initialDataMatch?initialDataMatch:0, name: "Coincidencia de datos iniciales :" },
          { value: lists?lists:0, name: "Listas :" },
        ],
      },
    ],
  };
};

export const getFunnelChartHyperFlow = (
  step1?: number,
  step3?: number,
  step4?: number,
) => {

  
  return {
    title: {
      top: 25,
      left: 55,
    },
    tooltip: {
      trigger: "item",
      formatter: " {a} <br/>{b}",
    },
    toolbox: {
      feature: {
        dataView: { readOnly: true },
        restore: {},
        saveAsImage: {},
      },
    },
    legend: {
      data: ["Crear Identidad Digital","Formulario", "Finalizo"],
      textStyle: {
        color: "#color",
      },
    },

    series: [
      {
        name: "Funnel HyperFlow",
        type: "funnel",
        left: "25%",
        top: 50,
        bottom: 60,
        width: "55%",
        min: 0,
        max: 100,
        minSize: "0%",
        maxSize: "100%",
        sort: "descending",
        gap: 5,
        label: {
          show: true,
          position: "inside",
          formatter: (params: { name: string; value: number; data: FunnelChartData; }) => {
            return `${params.value}`;
          },
          fontSize: 15,
          color: "white",
        },
        labelLine: {
          length: 20,
          lineStyle: {
            width: 1,
            type: "dashed",
          },
        },
        itemStyle: {
          borderColor: "black",
          borderWidth: 1,
        },

        emphasis: {
          label: {
            fontSize: 25,
          },
        },

        data: [
          { value: step1 ? step1:0, name: "Crear Identidad Digital" },
          { value: step3 ? step3:0, name: "Formulario" },
          { value: step4 ? step4:0, name: "Finalizo" },
        ],
      },
      {
        name: "Funnel HyperFlow",
        type: "funnel",
        left: "25%",
        top: 50,
        bottom: 60,
        width: "55%",
        min: 0,
        max: 100,
        minSize: "0%",
        maxSize: "100%",
        sort: "descending",
        gap: 5,
        label: {
          show: true,
          position: "left",
          formatter: (params: { name: string; value: number; data: FunnelChartData; }) => {
            return `${params.name} ${params.value}`;
          },
          fontSize: 15,
          color: "white",
          padding: 100,
        },
        labelLine: {
          length: 20,
          lineStyle: {
            width: 1,
            type: "dashed",
          },
        },
        itemStyle: {
          borderColor: "rgba(0, 0, 0, 0.0)", // Semitransparente para las fronteras
          borderWidth: 1,
          color: "rgba(255, 255, 255, 0.0)", // Transparente para el área del embudo
        },

        emphasis: {
          label: {
            fontSize: 18,
          },
        },

        data: [
          { value: step1,  name: "Crear Identidad Digital" },
          { value: step3, name: "Formulario" },
          { value: step4, name: "Finalizo" },
        ],
      },
    ],
  };
};
