import axiosApiInstance from "../../../helpers/interceptor";
import fileDownload from "js-file-download";

let getDashboardDataMethod = "coreId/miniDashboardData";
let getFilteredCoreId = "coreId/registration/getFiltered";
const getFilteredLeads = "lead/getFilteredLeads";
let getFilterOptions = "coreId/filterOptions";
let getRegistrationById = "coreId/registration";
let getRegistrationImagesMethod = (id: string) =>
  `coreId/registration/${id}/pictures`;
let getProjectsMethod = "coreId/projects";
let updateProjectsMethod = "coreId/updateProject";
let resetTriesMethod = (id: string) => `coreId/registration/${id}/resetTries`;
const toogleCheckLead = (id: string) => `lead/toggleChecked/${id}`;

let getMiniDashboardDataAPI = async (): Promise<{
  miniDasboardData: MiniDashboardData | null;
  error: string;
}> => {
  let dashboardData = await axiosApiInstance.get<MiniDashboardData>(
    `/${getDashboardDataMethod}`,
  );
  if (dashboardData.status !== 200) {
    return {
      miniDasboardData: null,
      error: `Se generó un código ${dashboardData.status}`,
    };
  } else {
    return { miniDasboardData: dashboardData.data, error: "" };
  }
};

let getFilteredRegistrationsAPI = async (
  parameters: FilteredRegistrationRequest,
): Promise<{ registrations: RegistrationsFiltered | null; error: string }> => {
  let registrationsResponse = await axiosApiInstance.post(
    `/${getFilteredCoreId}`,
    parameters,
  );
  if (registrationsResponse.status !== 200) {
    return { registrations: null, error: "" };
  } else {
    return {
      error: "",
      registrations: {
        ...registrationsResponse.data,
        currentPage: parameters.page,
      },
    };
  }
};

const getFilteredLeadsAPI = async (
  parameters: FilteredLeadsRequest,
): Promise<{ registrations: RegistrationsFiltered | null; error: string }> => {
  let registrationsResponse = await axiosApiInstance.post(
    `/${getFilteredLeads}`,
    parameters,
  );
  if (registrationsResponse.status !== 200) {
    return { registrations: null, error: "" };
  } else {
    return {
      error: "",
      registrations: {
        ...registrationsResponse.data,
        currentPage: parameters.page,
      },
    };
  }
};

const toogleCheckLeadAPI = async (id: string) => {
  try {
    let response = await axiosApiInstance.get<boolean>(toogleCheckLead(id));
    return response.data;
  } catch {
    return false;
  }
};

let getRegistrationByIdAPI = async (
  id: string,
): Promise<{ registration: RegistrationDetail | null; error: string }> => {
  let registration = await axiosApiInstance.get(
    `/${getRegistrationById}/${id}`,
  );
  if (registration.status !== 200) {
    return { registration: null, error: "" };
  } else {
    return { registration: registration.data, error: "" };
  }
};

let getImagesRegistrationAPI = async (
  id: string,
): Promise<{ pictures: Pictures | null; error: string }> => {
  let images = await axiosApiInstance.get(
    `/${getRegistrationImagesMethod(id)}`,
  );
  if (images.status !== 200) {
    return { pictures: null, error: "" };
  } else {
    return { pictures: images.data, error: "" };
  }
};

let changeStatusCoreIdAPI = async (
  registrationId: string,
  newStatus: string,
) => {
  let changed = await axiosApiInstance.post(
    `/coreId/registration/${registrationId}/changeStatus`,
    { newStatus },
  );
  return changed;
};

let getFilterOptionsAPI = async (): Promise<{
  filterOptions: CoreIdFilterOptions | null;
  error: string;
}> => {
  let filterOptions = await axiosApiInstance.get(`/${getFilterOptions}`);
  if (filterOptions.status !== 200) {
    return { filterOptions: null, error: "" };
  } else {
    return {
      error: "",
      filterOptions: filterOptions.data,
    };
  }
};

let getProjectsAPI = async (): Promise<{
  projects: Array<Project> | null;
  error: string;
}> => {
  let projects = await axiosApiInstance.get(`/${getProjectsMethod}`);
  if (projects.status !== 200) {
    return { error: "Error", projects: null };
  } else {
    let newProjects: Array<Project> = [];
    for (const project of projects.data) {
      let tempProject: Project = {
        ...project,
        authFactors: {
          authenticity: false,
          deviceId: true,
          documentCapture: true,
          email: true,
          faceCapture: true,
          faceMatch: true,
          governmentData: true,
          gps: true,
          internalLists: false,
          laft: false,
          legalPersonList: false,
          liveness: false,
          naturalPersonList: true,
          ocr: true,
          otp: true,
        },
        authThresholds: { faceMatch: 80 },
      };
      newProjects.push(tempProject);
    }
    return { error: "", projects: newProjects };
  }
};

let updateProjectAPI = async (
  project: Project,
): Promise<{ error: string; project: Project | null }> => {
  let update = await axiosApiInstance.put(`/${updateProjectsMethod}`, project);
  if (update.status !== 200) {
    return { error: "error", project: null };
  } else {
    return { error: "", project: update.data };
  }
};

export const downloadCoreIdImagesAPI = async (registrationId: string) => {
  try {
    let response = await axiosApiInstance.get(
      `/coreId/registration/${registrationId}/getImgZip`,
      { responseType: "blob" },
    );
    const link = document.createElement("a");
    link.href = URL.createObjectURL(new Blob([response.data]));
    link.download = `${registrationId}.zip`;

    link.click();

    window.URL.revokeObjectURL(link.href);
    return true;
  } catch (e) {
    return e;
  }
};

const getManualInfoAPI = async (req: DataManualInfo) => {
  try {
    let manualInfo = await axiosApiInstance.post<ListsResponse>(
      `/coreId/lists/getLists`,
      req,
    );
    return manualInfo.data;
  } catch {
    return null;
  }
};

const getFilteredListsAPI = async (req: GetFilteredListsRequest) => {
  try {
    let filteredLists = await axiosApiInstance.post<FilteredBlackListResponse>(
      `/coreId/lists/getFiltered`,
      req,
    );
    return filteredLists.data;
  } catch {
    return null;
  }
};

const generateListsReportAPI = async (id: string) => {
  try {
    let report = await axiosApiInstance.get<ListsReportResponse>(
      `/coreId/lists/generateReport/${id}`,
    );
    let url = `data:application/pdf;base64,${report.data.base64Data}`;

    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        fileDownload(blob, `reporte-${id}.pdf`);
      });
    return report.data;
  } catch {
    return null;
  }
};

const resetTriesAPI = async (
  id: string,
): Promise<{ error: string; success: boolean }> => {
  try {
    let reset = await axiosApiInstance.get(`/${resetTriesMethod(id)}`);
    return {
      error: "",
      success: true,
    };
  } catch {
    return {
      error: "Error",
      success: false,
    };
  }
};

export interface ListsReportResponse {
  base64Data: string;
}

export interface ListsResponse {
  lists: ExternalQueryResult;
  id: string;
}

export interface DataManualInfo {
  identification: string;
  name: string;
}

export interface MiniDashboardData {
  newRegs: number;
  totalRegs: number;
  regsSuccess: number;
  regsFailed: number;
  fraudRate: number;
}

export interface FilteredRegistrationRequest {
  result: string | null | undefined;
  projectId: string | null;
  searchQuery: string | null;
  page: number;
  pageSize?: number;
  startDate: string | null;
  endDate: string | null;
  tagId: string | null;
}

export interface FilteredLeadsRequest {
  hyperflow: boolean;
  page: number;
}

export interface RegistrationsFiltered {
  registrations: Array<Registration>;
  numPages: number;
  currentPage: number;
}

export interface Registration {
  fullName: string;
  dateTime: string;
  email: string;
  status: string;
  alerts: number;
  id: string;
  userIdentification?: string;
  phoneNumber?: string;
  tries?: number;
  checked?: boolean;
  abandoned?: boolean;
}

export interface EmailConfig {
  emailSubject: string;
  emailLogoUrl: string;
  helpEmailActive: boolean;
  helpEmailSubject: string;
  helpEmailCompanyName: string;
}

export interface BrandConfig {
  firstColorHex: string;
  secondColorHex: string;
  logoUrl: string;
}

export interface Project {
  name: string;
  supportsAuth: boolean;
  description: string;
  supporstMobile: boolean;
  supportsWeb: boolean;
  webHookUrl: string;
  usesLegalPerson: boolean;
  country: string;
  otpText: string;
  needsRut: boolean;
  hasWrongDataWebhook: boolean;
  webHookType: string;
  urlExpirationTime: number;
  emailConfig: EmailConfig;
  customFront: string;
  brandConfig: BrandConfig;
  hasLegalRepresentativeCheck: boolean;
  hasHumanAssistant: boolean;
  supportedDocTypes?: Array<string>;
  authFactors: AuthFactors;
  authThresholds: AuthThresholds;
  id: string;
}

export interface AuthFactors {
  ocr: boolean;
  otp: boolean;
  authenticity: boolean;
  email: boolean;
  naturalPersonList: boolean;
  legalPersonList: boolean;
  internalLists: boolean;
  documentCapture: boolean;
  faceCapture: boolean;
  gps: boolean;
  faceMatch: boolean;
  governmentData: boolean;
  deviceId: boolean;
  laft: boolean;
  liveness: boolean;
}

export interface AuthThresholds {
  faceMatch: number;
  liveness: number;
  authenticity: number;
}

export interface BioResponse {
  registrationId: string;
  requirementId: string;
  data: string;
  dataPath: string;
  result: boolean;
}

export interface Device {
  os: string;
  osVersion: string;
  deviceId: string;
  userId: string;
  screenHeigh: number;
  screenWidth: number;
  trusted: boolean;
  type: string;
  ip: string;
  numCpus: string;
  regId: string;
}

export interface Location {
  lat: number;
  lon: number;
  trusted: boolean;
  userId: string;
  locality: string;
  adminArea: string;
  state: string;
  country: string;
  postalCode: string;
  address: string;
  regId: string;
}

export interface ListResult {
  listName: string;
  resultDetails: string[];
  result: boolean;
  risk: boolean;
  warning: boolean;
  errorString: string;
  isBlocking: boolean;
  nameMatch: boolean;
  documentMatch: boolean;
  blocking: boolean;
}

export interface ExternalQueryResult {
  result: boolean;
  externalEntityName: string;
  externalEntityId: string;
  idConsultado: string;
  tipoIdConsultado: string;
  risk: boolean;
  warning: boolean;
  numConsultedLists: number;
  numResultsWithRisk: number;
  listResults: ListResult[];
}

export interface ListsRenderResult {
  numConsultedLists: number;
  numResultsWithRisk: number;
  numResultsWithWarning: number;
  lists: ListResult[];
}

export interface GovernmentData {
  entityName: string;
  document: string;
  fullName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  secondLastName: string;
  expirationDate: string;
  expeditionDate: string;
  sex: string;
  birthDate: string;
}

export interface InitialData {
  identification: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

export interface OcrCheck {
  result: boolean;
  active: boolean;
  description: string;
}

export interface GpsCheck {
  result: boolean;
  active: boolean;
  description: string;
}

export interface OtpCheck {
  result: boolean;
  active: boolean;
  description: string;
}

export interface EmailCheck {
  result: boolean;
  active: boolean;
  description: string;
}

export interface FacematchCheck {
  result: boolean;
  active: boolean;
  description: string;
}

export interface DocumentValidityCheck {
  result: boolean;
  active: boolean;
  description: string;
}

export interface DocumentMatchCheck {
  result: boolean;
  active: boolean;
  description: string;
}

export interface GovernmentDataMatchCheck {
  result: boolean;
  active: boolean;
  description: string;
}

export interface ListsCheck {
  result: boolean;
  active: boolean;
  description: string;
}

export interface DeviceIdCheck {
  result: boolean;
  active: boolean;
  description: string;
}

export interface DocumentCaptureCheck {
  result: boolean;
  active: boolean;
  description: string;
}

export interface UnifiedCheck {
  result: boolean;
  active: boolean;
  description: string;
}

export interface UnifiedChecks {
  ocrCheck: OcrCheck;
  gpsCheck: GpsCheck;
  otpCheck: OtpCheck;
  emailCheck: EmailCheck;
  facematchCheck: FacematchCheck;
  documentValidityCheck: DocumentValidityCheck;
  documentMatchCheck: DocumentMatchCheck;
  governmentDataMatchCheck: GovernmentDataMatchCheck;
  listsCheck: ListsCheck;
  deviceIdCheck: DeviceIdCheck;
  documentCaptureCheck: DocumentCaptureCheck;
  userConfirmedData: UnifiedCheck;
}

export interface UserDocument {
  country: string;
  type: string;
  documentNumber: string;
  expirationDate: string;
  expeditionDate: string;
  birthDate: string;
  firstName: string;
  middleName: string;
  lastName: string;
  secondLastName: string;
  aditionalData: object;
  gender: string;
  hasPicture: boolean;
  expeditionCity: string;
  birthPlace: string;
  birthState: string;
  bloodType: string;
}

export interface RegistrationDetail {
  id: string;
  projectId: string;
  clientId: string;
  userId: string;
  userIdType: string;
  userIdentification: string;
  isNaturalPerson: boolean;
  name: string;
  lastName: string;
  nit: string;
  startDatetime: string;
  email: string;
  phone: string;
  endDatetime: string;
  videoPath: string;
  result: boolean;
  bioResponses: BioResponse[];
  devices: Device[];
  location: Location;
  externalQueryResults: ExternalQueryResult[];
  hasAlerts: boolean;
  faceMatchPercent: number;
  country: string;
  governmentData: GovernmentData;
  customData: { [key: string]: string };
  initialData: InitialData;
  unifiedChecks: UnifiedChecks;
  unifiedChecksOk: boolean;
  rut: string;
  acceptedTyC: boolean;
  capturedUserDocument: UserDocument;
  userDocument: UserDocument;
  naturalPerson: boolean;
  pictures: Pictures;
  livenessPercent: number;
  attatchments?: Array<Attatchment>;
  multiFactorMatrix?: MultiFactorMatrix;
  status: string;
  abandoned?: boolean;
}

export interface MultiFactorMatrix {
  faceMatch: MultiFactorMatrixData;
  validity: MultiFactorMatrixData;
  veracity: MultiFactorMatrixData;
  lists: MultiFactorMatrixData;
  liveness: MultiFactorMatrixData;
  geolocation: MultiFactorMatrixData;
  deviceId: MultiFactorMatrixData;
  initialDataMatch: MultiFactorMatrixData;
  govEntity: MultiFactorMatrixData;
  impresion: MultiFactorMatrixData;
  screen: MultiFactorMatrixData;
  userDataConfirmation: MultiFactorMatrixData;
  finalScore: number;
  minScore: number;
}

export interface MultiFactorMatrixData {
  score: number;
  blockingThreshold: number;
  blocking: boolean;
  passes: boolean;
  weight: number;
  active: boolean;
  alerts: Array<MFAAlert>;
}
export interface MFAAlert {
  tag: string;
  description: string;
  detail: string;
}

export interface Pictures {
  facePics: Array<string>;
  docPics: Array<string>;
}
export interface Attatchment {
  fileName: string;
  stitchTypeId: string;
  fieldName: string;
  filledFormId: string;
}
export interface CoreIdFilterOptions {
  projects: { [key: string]: string };
}

export interface GetFilteredListsRequest {
  nameQuery: string | null;
  documentQuery: string | null;
  userId: string | null;
  matched: boolean | null;
  startedDate: string | null;
  finishedDate: string | null;
  page: number;
}

export interface FilteredBlackListResponse {
  numPages: number;
  reports: Array<BlackListReport>;
}

export interface BlackListReport {
  id: string;
  name: string;
  document: string;
  clientId: string;
  userId: string;
  result: ExternalQueryResult;
  consultationDate: string;
  matches: boolean | null;
  numListsConsulted: number;
  numListsMatched: number;
}

export {
  getMiniDashboardDataAPI,
  getFilteredRegistrationsAPI,
  getProjectsAPI,
  getFilterOptionsAPI,
  getRegistrationByIdAPI,
  getImagesRegistrationAPI,
  updateProjectAPI,
  changeStatusCoreIdAPI,
  getManualInfoAPI,
  getFilteredListsAPI,
  generateListsReportAPI,
  resetTriesAPI,
  getFilteredLeadsAPI,
  toogleCheckLeadAPI,
};
